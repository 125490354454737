@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--counters-content-image {
  padding: $grid-gutter-width / 2;

  @include media-breakpoint-down(lg) {
    padding: 0 $grid-gutter-width / 2;
  }

  + .section--customers-slider {
    margin-top: 2.625rem;

    @include media-breakpoint-down(md) {
      margin-top: 2.25rem;
    }
  }

  .counters__wrapper {
    padding: 7.75rem 0;

    @include media-breakpoint-down(lg) {
      padding: 3.5rem 0;
    }
  }

  .star-icon {
    display: inline-block;
    margin-bottom: 3.125rem;
    transform-origin: center;
    animation: star-blinking 4s infinite ease-in-out;

    @include media-breakpoint-down(lg) {
      margin-bottom: 2.25rem;
    }

    svg {
      width: 7.625rem;
      height: 7.625rem;

      @include media-breakpoint-down(lg) {
        width: 3.875rem;
        height: 3.875rem;
      }
    }
  }

  .content-wrapper {
    h2 {
      font-size: 3.375rem;
      line-height: 1.18;
      font-weight: 300;

      @include media-breakpoint-down(xl) {
        font-size: 3rem;
      }

      @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
        line-height: 1.1;
      }

      strong, b {
        font-weight: 800;
      }
    }
  }

  .counters__content {
    max-width: 38rem;
    margin-bottom: 3rem;

    @include media-breakpoint-down(md) {
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  .counters__image {
    width: 30.125rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 0.75rem;
    }

    @include media-breakpoint-down(sm) {
      width: 13.75rem;
    }
  }

  .counter-items {
    gap: 1.5rem;

    @include media-breakpoint-down(xl) {
      max-width: 800px;
      margin: 0 auto;
    }

    @include media-breakpoint-down(md) {
      gap: 0.875rem;
    }
  }

  .counter-item {
    padding: 1.25rem 2.5rem;
    min-width: 16.875rem;
    background-color: $purple;

    @include media-breakpoint-down(xxl) {
      padding: 18px 38px;
      min-width: 220px;
    }

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      @include media-breakpoint-down(lg) {
        margin-bottom: 4px;
      }
    }

    .number {
      position: relative;

      &__placeholder {
        opacity: 0;
        visibility: hidden;
      }

      &__counter {
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
      }
    }

    &__text {
      margin-bottom: 0;
      font-weight: 500;
      font-size: $font-size-sm;
      line-height: 1.2857;

      @include media-breakpoint-down(lg) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

@include color-mode(dark) {
  .section--counters-content-image {
    .counters__wrapper {
      background-color: #b6c7da !important;

      &.color--white {
        .content-wrapper {
          > h2, > .h2, > h3, > .h3, > p, > p > * {
            color: $body-color !important;
          }
        }
      }
    }

    .counter-item {
      .counter-item__title,
      .counter-item__text {
        color: $white !important;
      }

      &__text {
        color: $black;
      }

      &__title {
        color: $black !important;
      }
    }
  }
}
